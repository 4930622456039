<template>
  <div class="dt-left-wrapper wrapper" :class="{ wid100: Number(siteId) == 5 }">
    <div class="title">{{newsDt.title}}</div>
    <div class="publish_data">
      <span>发布时间：{{ newsDt.time }}</span>
      <span>来源：{{ newsDt.author }}</span>
      <span>阅读量：{{ newsDt.views }}</span>
    </div>
    <div class="news_content w-e-text-container" :style="{
        height: Number(siteId) === 5 && !authRes && showHeight ? showHeight + 'px' : 'auto',
        overflow: Number(siteId) === 5 && !authRes && articlePosition < 100 && articlePosition > 0 ? 'hidden' : 'auto'
      }">
      <div
        id="articleContent"
        data-slate-editor
        v-html="newsDt.detail"
      ></div>
      <div
        data-slate-editor
        id="articleContent"
        v-html="newsDt.detail"
      ></div>
      <div class="showAll_btn fc" v-if="Number(siteId) === 5 && !authRes && articlePosition < 100 && articlePosition > 0">
        <button
          @click="showAll"
        ><span class="text">查看全文</span></button>
      </div>
    </div>
    <div class="new_next">
      <p><span>上一篇：</span>
        <a
          v-if="newsDt.prev && newsDt.prev.id"
          @click="toDetail(newsDt.prev.id)"
        >{{ newsDt.prev.title }}</a>
        <template v-else>没有了！</template>
      <p><span>下一篇：</span>
        <a
          v-if="newsDt.next && newsDt.next.id"
          @click="toDetail(newsDt.next.id)"
        >{{ newsDt.next.title }}</a>
        <template v-else>没有了！</template>
      </p>
    </div>
    <div class="relative_article">
      <p>相关文章：</p>
      <ul>
        <li
          class="fb"
          v-for="i in listData"
          :key="i.id"
          @click="toDetail(i.id)"
        ><a>{{ i.title }}</a><span>{{ i.createTime | filterTime }}</span></li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { parseTime } from '@/utils'
import AuthModal from '@/plugins/AuthModal'
import { articlePage } from '@/api/api'
export default {
  props: ['newsDt'],
  computed: {
    ...mapState(['siteId', 'siteData', 'authRes']),
    articlePosition() {
      return this.siteData?.articlePosition  ? Number(this.siteData.articlePosition) : 100
    }
  },
  updated () {
  },
  data () {
    return {
      listData: [],
      showHeight: ''
    }
  },
  watch: {
    '$route.query.id': {
      handler (value) {
        // this.toDetail()
      }
    },
    'newsDt.id': {
      handler () {
        this.$nextTick(() => {
          let hei = document.getElementById('articleContent').clientHeight;
          this.showHeight = hei * this.articlePosition / 100;
        })
        this.getList()
      }
    }
  },
  filters: {
    filterTime (value) {
      return value ? parseTime(new Date(value.replace(/-/g, '/')), '{y}-{m}-{d}') : ''
    }
  },
  methods: {
    showAll() {
      AuthModal({
        title: '认证查看',
        articleId: this.$route.query.id,
        successCb: () => {
        },
        closeCb: () => {
        }
      })
    },
    getList () {
      articlePage({
        cateId: this.newsDt.cateId,
        page: 1,
        pageSize: 8,
      }).then(res => {
        let content = res.content.filter(i => i.id !== this.newsDt.id)
        this.listData = content || []
      })
    },
    toDetail (id) {
      this.$router.replace({ path: '/news/detail', query: { id } })
    }
  }
}
</script>

<style lang="scss" scoped>
.dt-left-wrapper {
  margin-bottom: 20px;
  flex: 0 0 70.5%;
  &.wid100 {
    flex: 0 0 100%;
  }
  .title {
    font-size: 24px;
    padding-top: 40px;
    font-weight: bold;
    color: #e95604;
    text-align: center;
  }
  .publish_data {
    text-align: center;
    padding: 20px 0 25px;
    border-bottom: 1px dashed #898989;
    span {
      padding-right: 30px;
      color: #898989;
      font-size: 15px;
    }
  }
  .news_content {
    padding: 40px 50px;
    font-size: 16px;
    line-height: 28px;
    position: relative;
    .showAll_btn {
      position: absolute;
      width: 100%;
      background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 1));
      padding: 50px 0;
      left: 0;
      bottom: 0;
      button {
        background: #e85603;
        border-color: #e85603;
        box-shadow: none;
        color: #fff;
        padding: 6px 40px;
        .text {
          margin-right: 10px;
        }
      }
    }
  }
  .new_next {
    padding: 10px 6%;
    background: #fbe9d8;
    p {
      line-height: 28px;
      font-size: 15px;
      color: #333;
      span {
        font-weight: bold;
        font-size: 15px;
        margin-right: 10px;
        color: #e85603;
      }
      a {
        color: #e85603;
        cursor: pointer;
      }
    }
  }
  .relative_article {
    padding: 20px 6%;
    p {
      font-weight: bold;
      font-size: 16px;
      line-height: 40px;
      border-bottom: 1px dashed #ccc;
      color: #e85603;
      padding-bottom: 3px;
    }
    ul {
      margin-top: 5px;
      li {
        font-size: 14px;
        line-height: 32px;
        height: 32px;
        a {
          color: #4a4a47;
          cursor: pointer;
        }
      }
    }
  }
}
</style>