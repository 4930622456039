<template>
  <div class="news-detail">
    <Banner :url="bannerUrl"></Banner>
    <b-container fluid="xl">
      <div class="search_nav fs"><img src="../../assets/images/arrow_ico.png"> <a>首页</a>-{{ newsDt.title }}</div>
      <div class="fb">
        <DtLeftWrapper :newsDt="newsDt"></DtLeftWrapper>
        <RightWrapper v-if="Number(siteId) !== 5"></RightWrapper>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getArticleDt } from '@/api/api'
import debounce from 'lodash.debounce'
import AuthModal from '@/plugins/AuthModal'
import Banner from '@/components/Banner'
import DtLeftWrapper from './components/DtLeftWrapper'
import RightWrapper from './components/RightWrapper'
export default {
  components: {
    Banner,
    DtLeftWrapper,
    RightWrapper
  },
  data () {
    return {
      newsDt: {},
      authModalShow: false,
    }
  },
  computed: {
    ...mapState(['siteData', 'siteId', 'authRes']),
    bannerUrl () {
      let url = ''
      let { pageBanner } = this.siteData
      let arr = pageBanner && pageBanner.length ? JSON.parse(pageBanner) : []
      arr = arr.map(i => typeof i == 'object' ? i : { image: i })
      url = arr[2] ? arr[2].image : ''
      return url
    },
     articlePosition() {
      return this.siteData?.articlePosition  ? Number(this.siteData.articlePosition) : 0
    }
  },
  watch: {
    '$route.query.id': {
      handler (value) {
        this.getArticleDt()
      }
    },
  },
  created () {
    this.getArticleDt()
  },
  beforeDestroy() {
    // window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollPercent = (scrollTop / (documentHeight - windowHeight)) * 100;
      const percent = scrollPercent.toFixed(2);
      if (windowHeight == documentHeight || percent > this.articlePosition) {
        this.checkShowModal();
      }
    },
    checkShowModal: debounce(function() {
      if (!this.authRes && this.siteId == 5) {
        if (this.authModalShow) return;
        this.authModalShow = true;
        AuthModal({
          title: '认证查看',
          articleId: this.$route.query.id,
          successCb: () => {
            window.removeEventListener('scroll', this.handleScroll);
          },
          closeCb: () => {
            this.$router.go(-1)
          }
        })
      }
      
    }, 1),
    getArticleDt () {
      getArticleDt({
        articleId: this.$route.query.id
      }).then(res => {
        if (!res) {
          this.$bvModal.msgBoxOk('您所浏览的文章不存在', {
            title: '提示',
            size: 'sm',
            okVariant: 'danger',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
          })
          // this.$bvToast.toast('提交成功', {
          //   title: `提示`,
          //   variant: 'danger',
          //   noCloseButton: true,
          //   toaster: 'b-toaster-top-center',
          //   append: false,
          //   autoHideDelay: 2000,
          //   solid: true
          return;
        }
        this.newsDt = res
        if (!this.authRes && this.siteId == 5) {
          // setTimeout(() => {
          //   this.handleScroll();
          // }, 10)
          // window.addEventListener('scroll', this.handleScroll);
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.news-detail {
  background: #eee;
  padding-bottom: 50px;
  overflow: hidden;
  .fb {
    align-items: flex-start;
  }
  .wrapper {
    border-top: 8px solid #e95604;
    background: #fff;
    padding-bottom: 20px;
  }
  .search_nav {
    width: 100%;
    height: 40px;
    margin-top: 15px;
    font-size: 16px;
    margin-bottom: 10px;
    img {
      display: inline-block;
      position: relative;
      top: 2px;
      margin-left: 20px;
      width: 14px;
      height: 15px;
      margin-right: 5px;
    }
    a {
      color: #3d3a3b;
    }
  }
}
</style>